import AllRoute from "./routes/Allroute";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <AllRoute />
      <NotificationContainer />
    </>
  );
}

export default App;
