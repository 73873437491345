import { configureStore } from "@reduxjs/toolkit";
import LoginAuth from "./LoginAuth";
import Orders from "./admin-store/Orders";
import HomeSlice from "./homeStore/home";
import Users from "./admin-store/Users";
import CatalogSlice from "./admin-store/CatalogSlice";
import BannerSlice from "./admin-store/BannerSlice";
import Product from "./admin-store/Product";
import RegisterUser from "./user-store/registerUser";
import UserSlice from "./user-store/user";
import UploadFile from "./admin-store/UploadFile";
import Stats from "./admin-store/Stats";
import SpecificsSlice from "./specifics/specifics";
import GeoSlice from "./admin-store/geo";
import DeliverySlice from "./admin-store/delivery";

export const store = configureStore({
  reducer: {
    auth: LoginAuth.reducer,
    home: HomeSlice.reducer,
    adminOrders: Orders.reducer,
    adminUsers: Users.reducer,
    catalog: CatalogSlice.reducer,
    banner: BannerSlice.reducer,
    product: Product.reducer,
    register:RegisterUser.reducer,
    user: UserSlice.reducer,
    file:UploadFile.reducer,
    stats: Stats.reducer,
    specifics: SpecificsSlice.reducer,
    geo: GeoSlice.reducer,
    delivery: DeliverySlice.reducer
  },
});
