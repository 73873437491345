import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

export const GetUsers = createAsyncThunk(
  "users/GetUsers",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "user/clients/?search=" +
          props?.search +
          ("&page=" + props?.page || 1) +
          ("&page-size=" + props?.page_size || 15)
      );
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetUser = createAsyncThunk(
  "users/GetUser",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`user/${props.id}/`);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
)

const Users = createSlice({
  name: "users",
  initialState: {
    data: null,
    user: null,
    status_data: ""
  },
  reducers: {},
  extraReducers: {
    [GetUsers.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetUsers.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.data = actions.payload;
    },
    [GetUsers.rejected]: (state) => {
      state.status_data = "rejected";
    },
    [GetUser.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetUser.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.user = actions.payload;
    },
    [GetUser.rejected]: (state) => {
      state.status_data = "rejected";
    }
  },
});

export const OrdersActions = Users.actions;
export default Users;
