import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

export const GetOrders = createAsyncThunk(
  "orders/GetOrders",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/crm/order-status/?status=" +
          props.search +
          "&page=" +
          (props.page || 1) +
          "&page-size=" +
          (props.page_size || 15)
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ChangeStatusOrder = createAsyncThunk(
  "orders/ChangeStatusOrder",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `v1/crm/order-status/${props.id}/update_status/`,
        {
          status: props.status,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetOrderDetail = createAsyncThunk(
  "orders/GetOrderDetail",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`v1/crm/order-status/${props}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const DeleteOneOrder = createAsyncThunk(
  "orders/DeleteOneOrder",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `v1/crm/order-status/${props}/`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const Orders = createSlice({
  name: "orders",
  initialState: {
    data: null,
    status_data: "",
    change_order_status: false,
    detail: null,
    detail_status: "",
  },
  reducers: {},
  extraReducers: {
    [GetOrderDetail.pending]: (state) => {
      state.detail_status = "pending";
    },
    [GetOrderDetail.fulfilled]: (state, actions) => {
      state.detail_status = "fulfilled";
      state.detail = actions.payload;
    },
    [GetOrderDetail.rejected]: (state) => {
      state.detail_status = "rejected";
    },
    [DeleteOneOrder.pending]: (state) => {
      state.change_order_status = !state.change_order_status;
    },
    [DeleteOneOrder.fulfilled]: (state, actions) => {
      state.change_order_status = !state.change_order_status;
    },
    [DeleteOneOrder.rejected]: (state) => {
      state.change_order_status = !state.change_order_status;
    },
    [ChangeStatusOrder.fulfilled]: (state) => {
      state.change_order_status = !state.change_order_status;
    },
    [ChangeStatusOrder.rejected]: (state) => {
      state.change_order_status = !state.change_order_status;
    },
    [GetOrders.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetOrders.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.data = actions.payload;
    },
    [GetOrders.rejected]: (state) => {
      state.status_data = "rejected";
    },
  },
});

export const OrdersActions = Orders.actions;
export default Orders;
