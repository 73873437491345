import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axiosInstans";

export const GetProduct = createAsyncThunk(
  "product/GetProduct",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/crm/products/?search/?name=" +
        props?.search +
        ("&page=" + props?.page || 1) +
        ("&page-size=" + props?.page_size || 15)
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const SearchProductByName = createAsyncThunk(
  'product/SearchProductByName',
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/crm/products/search/?name=" +
        props?.search +
        ("&page=" + props?.page || 1) +
        ("&page-size=" + props?.page_size || 15)
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const SearchProductByNameAndArticle = createAsyncThunk(
  'product/SearchProductByNameAndArticle',
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/products?search=" +
        props?.search +
        ("&banner_id=" + props?.id) +
        ("&page=" + props?.page || 1) +
        ("&page-size=" + props?.page_size || 15)
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const GetProductById = createAsyncThunk(
  'product/GetProductById',
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`v1/crm/products/${props}/`)
      return data.product
    } catch (error) {
      return rejectWithValue(error?.detail)
    }
  }
)

// export const PatchProductById = createAsyncThunk(
//   'product/PatchProductById',
//   async (props, { rejectWithValue }) => {
//     try {
//       console.log(props, "send data");
//       const formData = new FormData();
//       for (let i = 0; i < props.images.length; i++) {
//         formData.append("images", props.images[i]?.file);
//       }
//       for (const i in props) {
//         formData.append(`${i}`, props[i]);
//       }
//       for (const i in props?.product_specifics) {
//         formData.append(`product_specifics`, props?.product_specifics[i]);
//       }
//       const { data } = await ImageUploadingFetch.patch(`v1/crm/products/${props.id}/`, formData)
//       return data
//     } catch (error) {
//       return rejectWithValue(error?.detail)
//     }
//   }
// )
export const PatchProductById = createAsyncThunk(
  'product/PatchProductById',
  async (props, { rejectWithValue }) => {
    try {
      console.log(props, "send data");
      const formData = new FormData();

      if (props.images && Array.isArray(props.images)) {
        for (let i = 0; i < props.images.length; i++) {
          formData.append("images", props.images[i]?.file);
        }
      }

      for (const key in props) {
        if (key !== 'specifics' && key !== 'images') {
          formData.append(key, props[key]);
        }
      }

      for (const i in props?.specifics) {
        // formData.append("specifics", { specifics: JSON.stringify(props?.specifics[i]) });
        formData.append("specifics", JSON.stringify({specifics: props?.specifics[i]}));
      }

      const { data } = await ImageUploadingFetch.patch(`v1/crm/products/${props.id}/`, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail || error.message);
    }
  }
);


//delete product

export const DeleteProductById = createAsyncThunk(
  'product/DeleteProductById',

  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`v1/crm/products/${props}/`)
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail)
    }
  }
)


export const AddNewProduct = createAsyncThunk(
  "product/AddNewProduct",
  async (props, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < props.images.length; i++) {
        formData.append("images", props.images[i]?.file);
      }
      for (const i in props.data) {
        formData.append(`${i}`, props.data[i]);
      }
      for (const i in props?.specifics) {
        // formData.append("specifics", { specifics: JSON.stringify(props?.specifics[i]) });
        formData.append("specifics", JSON.stringify({specifics: props?.specifics[i]}));
      }
      console.log("form=",formData)
      // formData.append("product_specifics" , JSON.stringify(props.specifics))
      const { data } = ImageUploadingFetch.post("v1/crm/products/", formData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const Product = createSlice({
  name: "product",
  initialState: {
    data: null,
    detail: null,
    status_data: "",
    status_delete: '',
    status_search: '',
    status_detail: '',
    status_patch: '',
    status_add: '',
  },
  reducers: {
    ChangeStatusAddProduct: (state) => {
      state.status_add = ''
      state.status_delete = ''
      state.status_patch = ''

    }

  },
  extraReducers: {
    [GetProduct.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetProduct.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.data = actions.payload;
    },
    [GetProduct.rejected]: (state) => {
      state.status_data = "rejected";
    },
    [SearchProductByName.pending]: (state) => {
      state.status_search = 'pending'
    },
    [SearchProductByName.fulfilled]: (state, actions) => {
      state.status_search = 'fulfilled'
      state.data = actions.payload
    },
    [SearchProductByName.rejected]: (state) => {
      state.status_search = 'rejected'
    },
    [SearchProductByNameAndArticle.pending]: (state) => {
      state.status_search = 'pending'
    },
    [SearchProductByNameAndArticle.fulfilled]: (state, actions) => {
      state.status_search = 'fulfilled'
      state.data = actions.payload
    },
    [SearchProductByNameAndArticle.rejected]: (state) => {
      state.status_search = 'rejected'
    },
    [GetProductById.pending]: (state) => {
      state.status_detail = 'pending'
    },
    [GetProductById.fulfilled]: (state, actions) => {
      state.status_detail = 'fulfilled'
      state.detail = actions.payload
    },
    [GetProductById.rejected]: (state) => {
      state.status_detail = 'rejected'
    },
    [PatchProductById.pending]: (state) => {
      state.status_patch = 'pending'
    },
    [PatchProductById.fulfilled]: (state, actions) => {
      state.status_patch = 'fulfilled'
      state.detail = actions.payload
    },
    [PatchProductById.rejected]: (state) => {
      state.status_patch = 'rejected'
    },
    [DeleteProductById.pending]: (state) => {
      state.status_delete = 'pending'
    },
    [DeleteProductById.fulfilled]: (state, actions) => {
      state.status_delete = 'fulfilled'
      // state.detail = actions.payload
    },
    [DeleteProductById.rejected]: (state) => {
      state.status_delete = 'rejected'
    },
    [AddNewProduct.pending]: (state) => {
      state.status_add = 'pending'
    },
    [AddNewProduct.fulfilled]: (state) => {
      state.status_add = 'fulfilled'
    }
  },
  [AddNewProduct.rejected]: (state) => {
    state.status_add = 'rejected'
  }
});

export const { ChangeStatusAddProduct } = Product.actions;
export default Product;
