import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import axiosInstance, {
    ImageUploadingFetch
} from "../../api/axiosInstans";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

export const getBanners = createAsyncThunk(
    'banner/getBanners',
    async (props, {
        rejectWithValue
    }) => {
        try {
            const {
                data
            } = await axiosInstance.get('v1/banner/');
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getBannerById = createAsyncThunk(
    'banner/getBannerById',
    async (props, {
        rejectWithValue
    }) => {
        try {
            const {
                data
            } = await axiosInstance.get(`v1/banner/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const patchBanner = createAsyncThunk(
    'banner/patchBanner',
    async (banner, {
        rejectWithValue
    }) => {
        try {
            const formData = new FormData();
            if (typeof banner?.file !== 'string' && banner.file[0]?.file !== undefined) {
                formData.append("file", banner.file[0].file)
            }
            for(let i = 0; i < banner?.products?.length; i++) {
                formData.append("products", banner.products[i])
            }
            for (let key in banner) {
                if (key !== 'file' && key !== 'products') {
                    formData.append(key, banner[key])
                }
            }
            const {
                data
            } = await ImageUploadingFetch.patch(`v1/banner/${banner?.id}/`, formData);
            console.log(data)
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getProductByName = createAsyncThunk(
    'home/getProductByName',
    async ({name, id}, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/products/filter_search/?name=${name}${id !== undefined ? `&banner_id=${id}` : ''}`);
            return data;
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getAllProducts = createAsyncThunk(
    'banner/getAllProducts',
    async (props = 1, {rejectWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/products/?page=${props}&page_size=15/`);
            return data 
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getFilteredProducts = createAsyncThunk(
    'banner/getFilteredProducts',
    async ({page = 1, id = null}, {rejectWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/products/get_filtered_products/?page=${page}&page_size=15&product_id=${id}`);
            return data 
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const postBanner = createAsyncThunk(
    'banner/postBanner',
    async ({banner, typeUrl}, {rejectWithValue}) => {
        try {
            const formData = new FormData();
            if (typeof banner?.image !== 'string' && banner.file[0]?.file !== undefined) {
                formData.append("file", banner.file[0].file)
            }
            for(let i = 0; i < banner?.products?.length; i++) {
                formData.append("products", banner.products[i])
            }
            for (let key in banner) {
                if (key !== 'file' && key !== 'products') {
                    formData.append(key, banner[key])
                }
            }
            const {
                data
            } = await ImageUploadingFetch.post(typeUrl, formData);
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const BannerDeleteById = createAsyncThunk(
    'banner/BannerDeleteById',
    async (id, {rejectWithValue}) => {
        try {
            const { data } = await axiosInstance.delete(`v1/banner/${id}/`);
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// export const postBanner = createAsyncThunk(
//     'banner/patchBanner',
//     async ({banner, typeUrl}, {rejectWithValue}) => {
//         try {
//             const formData = new FormData();
//             if (typeof banner?.image !== 'string' && banner.image[0]?.file !== undefined) {
//                 formData.append("image", banner.image[0].file)
//             }
//             for(let i = 0; i < banner?.products?.length; i++) {
//                 formData.append("products", banner.products[i])
//             }
//             for (let key in banner) {
//                 if (key !== 'image' && key !== 'products') {
//                     formData.append(key, banner[key])
//                 }
//             }
//             const {
//                 data
//             } = await ImageUploadingFetch.post(typeUrl, formData);
//             return data
//         } catch (error) {
//             return rejectWithValue(error)
//         }
//     }
// )

const BannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        status: '',
        banner: {},
        banner_delete_status: '',
        banner_add_status: '',
        products: [],
    },
    reducers: {
        // deleteBannerById: async (state, action) => {
        //     const data = await axiosInstance.delete(`v1/banner/${action.payload}/`);
        //     console.log(data)
        //     // state.banner = state.banners.filter(item => item.id !== action.payload);
        // },
    },
    extraReducers: {
        [getBanners.pending]: (state) => {
            state.status = 'pending';
        },
        [getBanners.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.banners = action.payload;
        },
        [getBanners.rejected]: (state, action) => {
            state.status = 'rejected';
            state.banners = action.payload;
        },
        [getBannerById.pending]: (state) => {
            state.status = 'pending';
        },
        [getBannerById.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.banner = action.payload;
        },
        [getBannerById.rejected]: (state, action) => {
            state.status = 'rejected';
            state.banner = action.payload;
        },
        [patchBanner.pending]: (state) => {
            state.status = 'pending';
        },
        [patchBanner.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.banner = action.payload;
        },
        [patchBanner.rejected]: (state, action) => {
            state.status = 'rejected';
            state.banner = action.payload;
        },
        // [postBanner.pending]: (state) => {
        //     state.status = 'pending';
        // },
        // [postBanner.fulfilled]: (state, action) => {
        //     state.status = 'fulfilled';
        //     state.banner = action.payload;
        // },
        // [postBanner.rejected]: (state, action) => {
        //     state.status = 'rejected';
        //     state.banner = action.payload;
        // },
        [getProductByName.pending]: (state) => {
            state.status = 'pending';
        },
        [getProductByName.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.products = action.payload;
        },
        [getProductByName.rejected]: (state, action) => {
            state.status = 'rejected';
            state.products = action.payload;
        },
        [getAllProducts.pending]: (state) => {
            state.status = 'pending';
        },
        [getAllProducts.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.products = action.payload;
        },
        [getAllProducts.rejected]: (state, action) => {
            state.status = 'rejected';
            state.products = action.payload;
        },
        [getFilteredProducts.pending]: (state) => {
            state.status = 'pending';
        },
        [getFilteredProducts.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.products = action.payload;
        },
        [getFilteredProducts.rejected]: (state, action) => {
            state.status = 'rejected';
            state.products = action.payload;
        },
    }
})

export const BannerActions = BannerSlice.actions;
export default BannerSlice;