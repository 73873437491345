import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axiosInstans";

export const GetCatalogs = createAsyncThunk(
  "catalog/GetCatalogs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("v1/crm/catalogs/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetCatalogsDetail = createAsyncThunk(
  "catalog/GetCatalogsDetail",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/crm/catalogs/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetCategroyDetail = createAsyncThunk(
  "catalog/GetCategroyDetail",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "v1/crm/categories/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetCategory = createAsyncThunk(
  "catalog/GetCategory",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("v1/crm/categories/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetSubCategory = createAsyncThunk(
  "catalog/GetSubCategory",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("v1/crm/subcategories/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const AddNewCatalogs = createAsyncThunk(
  "catalog/AddNewCatalogs",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("image", props.image);
      formdata.append("name", props.name);
      formdata.append("slug", props.slug);

      const { data } = await ImageUploadingFetch.post(
        "v1/crm/catalogs/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const AddNewCategory = createAsyncThunk(
  "catalog/AddNewCategory",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("v1/crm/categories/", props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const AddNewSubCategory = createAsyncThunk(
  "catalog/AddNewSubCategory",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("v1/crm/subcategories/", props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const EditOneCategory = createAsyncThunk(
  "catalog/EditOneCategory",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        "v1/crm/categories/" + props.slug + "/",
        { name: props.name }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const EditOneSubCategory = createAsyncThunk(
  "catalog/EditOneSubCategory",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        "v1/crm/subcategories/" + props.slug + "/",
        { name: props.name }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const EditOneCatalog = createAsyncThunk(
  "catalog/EditOneCatalog",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      if (props.image) {
        formdata.append("image", props.image);
      }
      formdata.append("name", props.name);
      // formdata.append("slug", props.newSlug);
      const { data } = await ImageUploadingFetch.patch(
        "v1/crm/catalogs/" + props.slug + "/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const DeleteOneType = createAsyncThunk(
  "catalog/DeleteOneCatalog",
  async (props, {rejectWithValue}) => {
    try {
      const { data } = await axiosInstance.delete(`v1/crm/${props.type}/${props.slug}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const CatalogSlice = createSlice({
  name: "catalog",
  initialState: {
    data: null,
    status_data: "",
    add_change_status: false,
    category: null,
    sub_category: null,
    delete_status: '',
    add_catalog_status:'',
    add_catery_status:'',
    add_sub_category:'',
  },
  reducers: {
    deleteOneType: (state, action) => {
      axiosInstance.delete(`v1/crm/${action.payload.type}/${action.payload.slug}/`);
      if(action.payload.type === 'catalogs') {
        state.data = state.data.filter(item => item.slug !== action.payload.slug);
      }
      else if(action.payload.type === 'categories') {
        state.category = state.category.filter(item => item.slug !== action.payload.slug);
      } else {
        state.sub_category = state.sub_category.filter(item => item.slug !== action.payload.slug);
      }
      
    },
    ChangeStatusCatalog:(state) => {
      state.add_catalog_status = ''
      state.add_catery_status = ''
      state.add_sub_category = ''
    }
  },
  extraReducers: {
    [GetCategroyDetail.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetCategroyDetail.fulfilled]: (state, actions) => {
      state.status_data = "pending";
      state.sub_category = actions.payload?.subcategories;
    },
    [GetCategroyDetail.rejected]: (state) => {
      state.status_data = "pending";
    },
    [GetCatalogsDetail.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetCatalogsDetail.fulfilled]: (state, actions) => {
      state.status_data = "pending";
      state.category = actions.payload?.categories;
    },
    [GetCatalogsDetail.rejected]: (state) => {
      state.status_data = "pending";
    },
    [EditOneSubCategory.fulfilled]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [EditOneSubCategory.rejected]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [AddNewSubCategory.fulfilled]: (state) => {
      state.add_change_status = !state.add_change_status;
      state.add_sub_category = 'fulfilled'
    },
    [AddNewSubCategory.rejected]: (state) => {
      state.add_change_status = !state.add_change_status;
      state.add_sub_category = 'fulfilled'
    },
    [AddNewCatalogs.fulfilled]: (state) => {
      state.add_catalog_status = 'fulfilled'
      state.add_change_status = !state.add_change_status;
    },
    [AddNewCatalogs.rejected]: (state) => {
      state.add_catalog_status = 'rejected'
      state.add_change_status = !state.add_change_status;
    },
    [AddNewCategory.fulfilled]: (state) => {
      state.add_change_status = !state.add_change_status;
      state.add_catery_status = 'fulfilled'
    },
    [AddNewCategory.rejected]: (state) => {
      state.add_change_status = !state.add_change_status;
      state.add_catery_status = 'rejected'
    },
    [EditOneCatalog.fulfilled]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [EditOneCatalog.rejected]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [EditOneCategory.fulfilled]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [EditOneCategory.rejected]: (state) => {
      state.add_change_status = !state.add_change_status;
    },
    [GetSubCategory.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetSubCategory.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.sub_category = actions.payload;
    },
    [GetSubCategory.rejected]: (state) => {
      state.status_data = "rejected";
    },
    [GetCatalogs.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetCatalogs.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.data = actions.payload;
    },
    [GetCatalogs.rejected]: (state) => {
      state.status_data = "rejected";
    },
    [GetCategory.pending]: (state) => {
      state.status_data = "pending";
    },
    [GetCategory.fulfilled]: (state, actions) => {
      state.status_data = "fulfilled";
      state.category = actions.payload;
    },
    [GetCategory.rejected]: (state) => {
      state.status_data = "rejected";
    },
    [DeleteOneType.pending]: (state) => {
      state.delete_status = 'pending';
    },
    [DeleteOneType.fulfilled]: (state) => {
      state.delete_status = 'fulfilled';
    },
    [DeleteOneType.rejected]: (state) => {
      state.delete_status = 'rejected';
    }
  },
});

export const { deleteOneType  , ChangeStatusCatalog} = CatalogSlice.actions;
export default CatalogSlice;
