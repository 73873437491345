import {createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import {ImageUploadingFetch} from "../../api/axiosInstans";

export const SendExcelFile =  createAsyncThunk(
    'excelfile/SendExcelFile',
    async (props,{rejectWithValue}) => {
        
        try {
            const formData = new FormData()
            formData.append('file',props)
            const {data} = await ImageUploadingFetch.post('v1/upload_file/',formData)
            return data
        }catch (error) {
            return rejectWithValue(error)
        }
    }
)

const UploadFile = createSlice({
    name:'excelfile',
    initialState:{
        file:{},
        statusFile:'',
        error:null,
    },
    reducers:{
        ChangeStatusError:(state) => {
            state.error = null
    }},
    extraReducers:{
        [SendExcelFile.pending]: (state) => {
            state.statusFile = 'pending'
        },
        [SendExcelFile.fulfilled]: (state , actions) => {
            state.statusFile = 'fulfilled'
            state.file = actions.payload
        },
        [SendExcelFile.rejected]: (state , actions) => {
            state.statusFile = 'rejected'
            state.error = actions.payload
        }
    },
})

export const {ChangeStatusError} = UploadFile.actions
export default UploadFile;