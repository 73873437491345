import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

export const GetStats = createAsyncThunk(
  "stats/GetStatsOrders",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("v1/crm/stats/?start_time=" + props.start + "&end_time=" + props.end);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const Stats = createSlice({
  name: "stats",
  initialState: {
    stats_status: '',
    stats: [],
  },
  reducers: {},
  extraReducers: {
    [GetStats.pending]: (state) => {
      state.stats_status = "pending";
    },
    [GetStats.fulfilled]: (state, actions) => {
      state.stats_status = "fulfilled";
      state.stats = actions.payload;
    },
    [GetStats.rejected]: (state, actions) => {
      state.stats_status = "rejected";
      state.stats = actions.payload;
    },
  },
});
export const StatsActions = Stats.actions;
export default Stats;
