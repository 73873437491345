import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

export const getFavorites = createAsyncThunk(
    'user/getFavorites',
    async () => {
        try {
            const { data } = await axiosInstance.get(`v1/bookmark/`);
            return data[0]?.product;
        } catch (err) {
            return err
        }
    }
)

export const getBasketProducts = createAsyncThunk(
    'user/getBasketProducts',
    async () => {
        try {
            const { data } = await axiosInstance.get(`v1/cart/`);
            return data?.cart_products
        } catch (err) {
            return err
        }
    }
)

export const postFavorite = createAsyncThunk(
    'user/postFavorite',
    async (id) => {
        await axiosInstance.delete(`v1/bookmark/${id}`);
    }
)

export const getOrderHistory = createAsyncThunk(
    'user/getOrderHistory',
    async ({page = 1}) => {
        try {
            const { data } = await axiosInstance.get(`v1/history/?page=${page}`);
            return data
        } catch (err) {
            return err
        }
    }
)


export const getBranches = createAsyncThunk(
    'user/getBranches',
    async () => {
        try {
            const { data } = await axiosInstance.get(`v1/branches/`);
            return data;
        } catch (err) {
            return err;
        }
    }
)

export const getCountries = createAsyncThunk(
    'user/getCountries',
    async () => {
        try {
            const { data } = await axiosInstance.get(`/countries/`);
            return data;
        } catch (err) {
            return err
        }
    }
)

export const getRegions = createAsyncThunk(
    'user/getRegions',
    async (props) => {
        try {
            const { data } = await axiosInstance.get(`/countries/${props}`);
            return data;
        } catch (err) {
            return err
        }
    }
)

export const calculateOrder = createAsyncThunk(
    'user/calculateOrder',
    async (props) => {
        try {
            const { data } = await axiosInstance.post(`/v1/calculate/order-price/`, props);
            return data
        } catch (err) {
            return err
        }
    }
)

const initialState = {
    favorites: [],
    basketProduct: [],
    orderHistory: [],
    branches:  [],
    countries: [],
    regions: [],
    status: '',
    statusReboot: false,
    statusFavorites:'',
    statusBasket:'',
    orderId: '',
    localBasket: JSON.parse(localStorage.getItem("localBasket"))?.length,
    delivery_price: 0,
    price_before: 0,
    price_after: 0,
    delivery_status: '',
    delivery_info: {}
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        changeLocalBasketLength: (state) => {
            state.localBasket = !state.localBasket;
            state.statusReboot = !state.statusReboot;
        },
        favoriteDeleted: (state, action) => {
            state.favorites = state.favorites?.filter(item => item.id !== action.payload);
        },
        favoriteAdd: (state, action) => {
            state.favorites = [...state.favorites, action.payload]
        },
        basketProductAdd: (state, action) => {
            if(state.basketProduct?.length > 0){
                state.basketProduct = [...state.basketProduct, action.payload];
            }else{
                state.basketProduct = [action.payload];
            }
        },
        basketProductDeleted: (state, action) => {
            axiosInstance.delete(`v1/cart/${action.payload}/`);
            state.statusReboot = !state.statusReboot;
        },
        changeQuantityProducts: (state, action) => {
            axiosInstance.put(`v1/cart/`, { cart_product_id: action.payload.id, quantity: (action.payload.count + action.payload.x)})
        },
        inputQuantityProducts: (state, action) => {
            axiosInstance.put(`v1/cart/`, { cart_product_id: action.payload.id, quantity: action.payload.x});
            state.statusReboot = !state.statusReboot;
        }
    },
    extraReducers: {
        [calculateOrder.pending]: (state) => {
            state.delivery_status = 'pending';
        },
        [calculateOrder.fulfilled]: (state, action) => {
            state.delivery_price = action.payload.delivery_price;
            state.price_after = action.payload.price_after;
            state.price_before = action.payload.price_before;
            state.delivery_info = action.payload;
            state.delivery_status = 'fulfilled';
        },
        [calculateOrder.rejected]: (state, action) => {
            state.delivery_status = 'rejected';
        },
        [getFavorites.pending]: (state) => {
            state.statusFavorites = 'pending';
        },
        [getFavorites.fulfilled]: (state, action) => {
            state.statusFavorites = 'fulfilled';
            state.favorites = action.payload;
        },
        [getFavorites.rejected]: (state, action) => {
            state.statusFavorites = 'rejected';
            state.favorite = action.payload;
        },
        [getBasketProducts.pending]: (state) => {
            state.statusBasket = 'pending';
        },
        [getBasketProducts.fulfilled]: (state, action) => {
            state.statusBasket = 'fulfilled';
            state.basketProduct = action.payload;
        },
        [getBasketProducts.rejected]: (state, action) => {
            state.statusBasket = 'rejected';
            state.basketProduct = action.payload;
        },
        [getOrderHistory.pending]: (state) => {
            state.status = 'pending';
        },
        [getOrderHistory.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.orderHistory = action.payload;
        },
        [getOrderHistory.rejected]: (state, action) => {
            state.status = 'rejected';
            state.orderHistory = action.payload;
        },
        [getBranches.pending]: (state) => {
            state.status = 'pending';
        },
        [getBranches.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.branches = action.payload;
        },
        [getBranches.rejected]: (state, action) => {
            state.status = 'rejected';
            state.branches = action.paylaod;
        },
        [getCountries.pending]: (state) => {
            state.status = 'pending';
        },
        [getCountries.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.countries = action.payload;
        },
        [getCountries.rejected]: (state, action) => {
            state.status = 'rejected';
            state.countries = action.payload;
        },
        [getRegions.pending]: (state) => {
            state.status = 'pending';
        },
        [getRegions.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.regions = action.payload;
        },
        [getRegions.rejected]: (state, action) => {
            state.status = 'rejected';
            state.regions = action.paylaod;
        }
    }
})

export default UserSlice;
export const {
    favoriteDeleted,
    favoriteAdd,
    basketProductAdd,
    basketProductDeleted,
    changeQuantityProducts,
    inputQuantityProducts,
    changeLocalBasketLength
} = UserSlice.actions;