import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes , Outlet} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import ProtecteRoute from "./ProtecteRoute";


const AllRoute = () => {

  const { login_data } = useSelector(state => state.auth);
  const login = JSON.parse(localStorage.getItem('login'));
  const role = login_data.status || login?.status;
  const location = useLocation();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path='resetpassword' element={<ResetPasswordPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="category/:category" element={<CategoryPage />} />
          <Route path="category/:category/:subCategory" element={<SubCategotyPage />} />
          <Route path="category/:category/:subCategory/:products" element={<ProductsPage />} />
          <Route path="category/:category/:subCategory/:products/:id" element={<DetailProductPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/search/:name" element={<SearchPage />} />
          <Route path="product/:id" element={<DetailPage />} />
          <Route path="wholesaler" element={<InformationWholesaler />} />
          <Route path="discountproducts/:id" element={<DiscountProductsPage />} />
          <Route path="saleproducts/:id" element={<SaleProductsPage />} />
          <Route path="user/basket" element={<BasketPage />} />
          <Route path="user/decoration" element={<RegistrationOrderPage />} />
          <Route path="politic" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="/user/*" element={
          <ProtecteRoute
            textrole='Пользователь'
            role={role}
            path={role === 'Пользователь' ? '' : '/login'}
          >
            <UserLayout />
          </ProtecteRoute>
        } >
          <Route path="history" element={<OrderHistoryPage />} />
          <Route path="favorites" element={<FavoritesPage />} />
          <Route path="basket" element={<BasketPage />} />
          <Route path="electronicwrallet" element={<ElectronicWalletPage />} />
          <Route path="succespaid" element={<SuccessfullPaidPage />} />
          <Route path="" element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<UserPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="/crm/*" element={
          <ProtecteRoute
            textrole='Менеджер'
            role={role}
            path={role === 'Менеджер' ? '' : '/login'}
          >
            <AdminLayout />
          </ProtecteRoute>
        }>
          <Route path="" element={<Navigate to="" replace />} />
          <Route path="home" element={<HomeCrmPage />} />
          <Route path="user" element={<Users />} />
          <Route path="user/:id" element={<UserDetailPage />} />
          <Route path="downloadfile" element={<DownloadFilePage />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<OrderDetailPage />} />
          <Route path="banner" element={<BannerPage />} />
          <Route path="banner/:id" element={<BannerDetailPage />} />
          <Route path="banner/:id/edit" element={<BannerEditPage />} />
          <Route path="banner/add" element={<BannerAddPage />} />
          <Route path="products" element={<ProductsCrmPage />} />
          <Route path='products/:id' element={<ProductCrmDetailPage />} />
          <Route path="products/add" element={<TestAdd />} />
          <Route path="catalog/*" element={<CatalogPage />}>
            <Route path="" element={<Navigate to="catalog" replace />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="category" element={<Category />} />
            <Route path="sub_category" element={<SubCategory />} />
          </Route>
          <Route path='feature' element={<Outlet />}>
            <Route path="" element={<FeatureList />} />
            <Route path="add" element={<FeatureAdd />} />
            <Route path=":id" element={<FeatureDetail />} />
            <Route path=":id/edit" element={<FeatureEdit />} />
          </Route>
          <Route path="delivery" element={<CrmDelivery />} />
          <Route path="geo" element={<GeoLocation />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="404" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};
export default AllRoute;


// home layoute
const HomeLayout = lazy(() => import('../layout/HomeLayout'))
//home pages
const HomePage = lazy(() => import('../pages/home-page/HomePage'))
const LoginPage = lazy(() => import('../pages/home-page/LoginPage'))
const ResetPasswordPage = lazy(() => import('../pages/home-page/ResetPasswordPage'))
const RegisterPage = lazy(() => import('../pages/home-page/RegisterPage'))
const CategoryPage = lazy(() => import('../pages/home-page/CategoryPage'))
const SubCategotyPage = lazy(() => import('../pages/home-page/SubCategotyPage'))
const ProductsPage = lazy(() => import('../pages/home-page/ProductsPage'))
const DetailProductPage = lazy(() => import('../pages/home-page/DetailProductPage'))
const DetailPage = lazy(() => import('../pages/home-page/DetailPage'))
const SearchPage = lazy(() => import('../pages/home-page/SearchPage'))
const DiscountProductsPage = lazy(() => import('../pages/home-page/DiscountProductsPage'))
const SaleProductsPage = lazy(() => import('../pages/home-page/SaleProductsPage'))
const InformationWholesaler = lazy(() => import('../pages/home-page/InformationWholesaler'))
const PrivacyPolicyPage = lazy(() => import("../pages/home-page/PrivacyPolicyPage"))

//user layoute
const UserLayout = lazy(() => import('../layout/UserLayout'))
//user pages
const OrderHistoryPage = lazy(() => import('../pages/user-page/OrderHistoryPage'))
const FavoritesPage = lazy(() => import('../pages/user-page/FavoritesPage'))
const RegistrationOrderPage = lazy(() => import('../pages/user-page/RegistrationOrderPage'))
const ElectronicWalletPage = lazy(() => import('../pages/user-page/ElectronicWalletPage'))
const SuccessfullPaidPage = lazy(() => import('../pages/user-page/SuccessfullPaidPage'))
const UserPage = lazy(() => import('../pages/user-page/UserPage'))
const BasketPage = lazy(() => import('../pages/user-page/BasketPage'))
//admin layoute
const AdminLayout = lazy(() => import('../layout/AdminLayout'))
//admin pages
const CrmDelivery = lazy(() => import('../pages/crm-page/CrmDelivery'))
const GeoLocation = lazy(() => import('../pages/crm-page/GeoLocation'))
const FeatureEdit = lazy(() => import('../pages/crm-page/feature/FeatureEdit'))
const FeatureList = lazy(() => import('../pages/crm-page/feature/FeatureList'))
const FeatureAdd = lazy(() => import('../pages/crm-page/feature/FeatureAdd'))
const FeatureDetail = lazy(() => import('../pages/crm-page/feature/FeatureDetail'))
const HomeCrmPage = lazy(() => import('../pages/crm-page/HomeCrmPage'))
const Users = lazy(() => import('../pages/crm-page/Users'))
const UserDetailPage = lazy(() => import('../pages/crm-page/UserDetailPage'))
const DownloadFilePage = lazy(() => import('../pages/crm-page/DownloadFilePage'))
const Orders = lazy(() => import('../pages/crm-page/Orders'))
const OrderDetailPage = lazy(() => import('../pages/crm-page/OrderDetailPage'))
const ProductsCrmPage = lazy(() => import('../pages/crm-page/product/ProductsCrmPage'))
const ProductCrmDetailPage = lazy(() => import('../pages/crm-page/product/ProductCrmDetailPage'))
const CatalogPage = lazy(() => import('../pages/crm-page/CatalogPage'))
const BannerPage = lazy(() => import('../pages/crm-page/BannerPage'))
const BannerDetailPage = lazy(() => import('../pages/crm-page/banner/BannerDetailPage'))
const BannerEditPage = lazy(() => import('../pages/crm-page/banner/BannerEditPage'))
const BannerAddPage = lazy(() => import('../pages/crm-page/banner/BannerAddPage'))
const Catalog = lazy(() => import('../pages/crm-page/catalog-page/Catalog'))
const Category = lazy(() => import('../pages/crm-page/catalog-page/Category'))
const SubCategory = lazy(() => import('../pages/crm-page/catalog-page/SubCategory'))
const Loader = lazy(() => import('../ui/Loader'))
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'))



// add product page crm
const TestAdd = lazy(() => import('../pages/crm-page/product/test'))