import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstans";

export const Login = createAsyncThunk(
  "login/Login",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("token/", props.user);
      localStorage.setItem("login", JSON.stringify(data));
      localStorage.setItem("accessToken", data.access);
      if (data.status === "Менеджер") {
        props.gopage("/crm/home");
      }
      if (data.status !== "Менеджер") {
        props.gopage("/user/profile");
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const ForgotPassword = createAsyncThunk(
    'forgotpassword',
    async (props , {rejectWithValue}) => {
      try {
        const {data} = await axiosInstance.post('forgot-password/', {email:props})
        return data
      }catch (error) {
        return  rejectWithValue(error?.detail)
      }
    }
)

export const ConfirmationCode = createAsyncThunk(
    'confirmationcode',
    async (props , {rejectWithValue}) => {
      try {
        const {data} = await axiosInstance.post('set-password/',props)
        return data
      }catch (error) {
        return rejectWithValue(error?.detail)
      }
    }
)


const LoginAuth = createSlice({
  name: "login",
  initialState: {
    login_data: JSON.parse(localStorage.getItem("login")) || {},
    loaded: "",
    status:'',
    data: {},
    codeData:{},
    statusCode :'',
  },
  reducers: {
    logout: (state) => {
      state.login_data = {};
      state.loaded = "";
      localStorage.removeItem("login");
      localStorage.removeItem("accessToken");
    },
    ChangeStatusLogin:(state) => {
      state.status = ''
      state.statusCode = ''
      state.loaded = ''
    }
  },
  extraReducers: {
    [Login.pending]: (state) => {
      state.loaded = "pending";
    },
    [Login.fulfilled]: (state, actions) => {
      state.loaded = "fulfilled";
      state.login_data = actions.payload;
    },
    [Login.rejected]: (state) => {
      state.loaded = "rejected";
    },
    [ForgotPassword.pending]: (state) => {
      state.status = 'pending'
    },
    [ForgotPassword.fulfilled]: (state , actions) => {
      state.status = 'fulfilled'
      state.data = actions.payload
    },
    [ForgotPassword.rejected]: (state ,actions) => {
      state.status = 'rejected'
      state.data = actions.payload
    },
    [ConfirmationCode.pending]: (state) => {
      state.statusCode = 'pending'
    },
    [ConfirmationCode.fulfilled]:(state , actions) => {
      state.statusCode = 'fulfilled'
      state.codeData = actions.payload
    },
    [ConfirmationCode.rejected]:(state , actions) => {
      state.statusCode = 'rejected'
      state.codeData = actions.payload
    }
  },
});
export const {ChangeStatusLogin , logout} = LoginAuth.actions;
export default LoginAuth;
