import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

// request 
export const getCountriesList = createAsyncThunk(
    "geo/getCountriesList",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("v1/crm/countries/");
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// create country
export const createCountry = createAsyncThunk(
    "geo/createCountry",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post("v1/crm/countries/", props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// delete country
export const deleteCountry = createAsyncThunk(
    "geo/deleteCountry",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/countries/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// get country by id
export const getCountryById = createAsyncThunk(
    "geo/getCountryById",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/countries/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// edit country
export const editCountry = createAsyncThunk(
    "geo/editCountry",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/countries/${props.slug}/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// regions 
export const getRegionsList = createAsyncThunk(
    "geo/getRegionsList",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/regions/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// create regions 
export const createRegion = createAsyncThunk(
    "geo/createRegion",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(`v1/crm/regions/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const deleteRegion = createAsyncThunk(
    "geo/deleteRegion",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/regions/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const editRegion = createAsyncThunk(
    "geo/editRegion",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/regions/${props.slug}/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// branches 
export const getBranchesList = createAsyncThunk(
    "geo/getBranchesList",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/branches/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// create branches
export const createBranches = createAsyncThunk(
    "geo/createBranches",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(`v1/crm/branches/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// delete branches
export const deleteBranches = createAsyncThunk(
    "geo/deleteBranches",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/branches/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// edit branches
export const editBranches = createAsyncThunk(
    "geo/editBranches",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/branches/${props.slug}/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// slice 
const GeoSlice = createSlice({
    name: "geo",
    initialState: {
        countries_list: [],
        countries_list_status: "",
        create_country_status: "",
        delete_country_status: "",
        country: {},
        country_status: "",
        edit_country_status: "",


        // regions
        regions_list: [],
        regions_list_status: "",
        create_region_status: "",
        delete_region_status: "",
        edit_region_status: "",

        // branches
        branches_list: [],
        branches_list_status: "",
        create_branch_status: "",
        delete_branch_status: "",
        edit_branch_status: "",
    },
    reducers: {
        clearStatus: (state) => {
            state.countries_list_status = ""
            state.create_country_status = ""
            state.delete_country_status = ""
            state.country_status = ""
            state.edit_country_status = ""

            state.regions_list_status = ""
            state.create_region_status = ""
            state.delete_region_status = ""
            state.edit_region_status = ""

            state.branches_list_status = ""
            state.create_branch_status = ""
            state.delete_branch_status = ""
            state.edit_branch_status = ""
        }
    },
    extraReducers: {
        [getCountriesList.pending]: (state) => {
            state.countries_list_status = "pending";
        },
        [getCountriesList.fulfilled]: (state, actions) => {
            state.countries_list_status = "fulfilled";
            state.countries_list = actions.payload;
        },
        [getCountriesList.rejected]: (state) => {
            state.countries_list_status = "rejected";
        },
        // ----------------------------------------------------- //
        [createCountry.pending]: (state) => {
            state.create_country_status = "pending";
        },
        [createCountry.fulfilled]: (state, actions) => {
            state.create_country_status = "fulfilled";
        },
        [createCountry.rejected]: (state) => {
            state.create_country_status = "rejected";
        },
        // ----------------------------------------------------- //
        [deleteCountry.pending]: (state) => {
            state.delete_country_status = "pending";
        },
        [deleteCountry.fulfilled]: (state, actions) => {
            state.delete_country_status = "fulfilled";
        },
        [deleteCountry.rejected]: (state) => {
            state.delete_country_status = "rejected";
        },
        // ----------------------------------------------------- //
        [getCountryById.pending]: (state) => {
            state.country_status = "pending";
        },
        [getCountryById.fulfilled]: (state, actions) => {
            state.country_status = "fulfilled";
            state.country = actions.payload;
        },
        [getCountryById.rejected]: (state) => {
            state.country_status = "rejected";
        },
        // ----------------------------------------------------- //
        [editCountry.pending]: (state) => {
            state.edit_country_status = "pending";
        },
        [editCountry.fulfilled]: (state, actions) => {
            state.edit_country_status = "fulfilled";
        },
        [editCountry.rejected]: (state) => {
            state.edit_country_status = "rejected";
        },
        // ----------------------------------------------------- //
        [getRegionsList.pending]: (state) => {
            state.regions_list_status = "pending";
        },
        [getRegionsList.fulfilled]: (state, actions) => {
            state.regions_list_status = "fulfilled";
            state.regions_list = actions.payload;
        },
        [getRegionsList.rejected]: (state) => {
            state.regions_list_status = "rejected";
        },
        // ----------------------------------------------------- //
        [createRegion.pending]: (state) => {
            state.create_region_status = "pending";
        },
        [createRegion.fulfilled]: (state, actions) => {
            state.create_region_status = "fulfilled";
        },
        [createRegion.rejected]: (state) => {
            state.create_region_status = "rejected";
        },
        // ----------------------------------------------------- //
        [deleteRegion.pending]: (state) => {
            state.delete_region_status = "pending";
        },
        [deleteRegion.fulfilled]: (state, actions) => {
            state.delete_region_status = "fulfilled";
        },
        [deleteRegion.rejected]: (state) => {
            state.delete_region_status = "rejected";
        },
        // ----------------------------------------------------- //
        [editRegion.pending]: (state) => {
            state.edit_region_status = "pending";
        },
        [editRegion.fulfilled]: (state, actions) => {
            state.edit_region_status = "fulfilled";
        },
        [editRegion.rejected]: (state) => {
            state.edit_region_status = "rejected";
        },
        // ----------------------------------------------------- //
        [getBranchesList.pending]: (state) => {
            state.branches_list_status = "pending";
        },
        [getBranchesList.fulfilled]: (state, actions) => {
            state.branches_list_status = "fulfilled";
            state.branches_list = actions.payload;
        },
        [getBranchesList.rejected]: (state) => {
            state.branches_list_status = "rejected";
        },
        // ----------------------------------------------------- //
        [createBranches.pending]: (state) => {
            state.create_branch_status = "pending";
        },
        [createBranches.fulfilled]: (state, actions) => {
            state.create_branch_status = "fulfilled";
        },
        [createBranches.rejected]: (state) => {
            state.create_branch_status = "rejected";
        },
        // ----------------------------------------------------- //
        [deleteBranches.pending]: (state) => {
            state.delete_branch_status = "pending";
        },
        [deleteBranches.fulfilled]: (state, actions) => {
            state.delete_branch_status = "fulfilled";
        },
        [deleteBranches.rejected]: (state) => {
            state.delete_branch_status = "rejected";
        },
        // ----------------------------------------------------- //
        [editBranches.pending]: (state) => {
            state.edit_branch_status = "pending";
        },
        [editBranches.fulfilled]: (state, actions) => {
            state.edit_branch_status = "fulfilled";
        },
        [editBranches.rejected]: (state) => {
            state.edit_branch_status = "rejected";
        },
    },
});


export const {clearStatus} = GeoSlice.actions
export default GeoSlice