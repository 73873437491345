import axios from "axios";
import {Navigate} from "react-router-dom";

const endSession = () => {
    return <Navigate to="/" replace/>;
};


export const Base_URL = "https://beautypro.kg/api/";
//export const Base_URL = "http://45.10.162.50/api/";

export const ImageUploadingFetch = axios.create({
    baseURL: Base_URL,
});

const axiosInstance = axios.create({
    baseURL: Base_URL,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    ({response}) => {
        if (response?.status === 401) {
            endSession();
        }
        return Promise.reject(
            (response && response.data) || "При запросе произошла ошибка"
        );
    }
);

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        return {
            ...config,
            headers: {
                ...config.headers,
                "Content-Type": "application/json",
                ...(!accessToken ? {} : {Authorization: `Bearer ${accessToken}`}),
            },
        };
    },
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
        )
);

ImageUploadingFetch.interceptors.response.use(
    (response) => response,
    ({response}) => {
        if (response?.status === 401) {
            endSession();
        }
        return Promise.reject(
            (response && response.data) || "При запросе произошла ошибка"
        );
    }
);

ImageUploadingFetch.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        return {
            ...config,
            headers: {
                ...config.headers,
                "Content-Type": "multipart/form-data",
                ...(!accessToken ? {} : {Authorization: `Bearer ${accessToken}`}),
            },
        };
    },
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
        )
);
export default axiosInstance;
