import {createAsyncThunk , createSlice} from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

const _transformProduct = (data) => {
    return {
        id: data?.product?.id,
        category: data?.category_name,
        category_slug: data?.category,
        catalog: data?.catalog_name,
        catalog_slug: data?.catalog,
        subcategory: data?.subcategory_name,
        subcategory_slug: data?.subcategory,
        name : data?.product?.name,
        description: data?.product?.description,
        price: data?.product?.price,
        quantity: data?.product?.quantity,
        sale_price: data?.product?.sale_price,
        status: data?.product.status === null ? 'Без акции' : data?.product.status,
        symbol_code: data?.product?.symbol_code,
        article: data?.product?.article, 
        brand: data?.product?.brand,
        power: data?.product?.power?.includes('/') ? data?.product?.power?.split('/') : data?.product?.power?.split(' '),
        color: data?.product?.color?.includes('/') ? data?.product?.color?.split('/') : data?.product?.color?.split(' '),
        material: data?.product?.material?.includes('/') ? data?.product?.material.split('/') : data?.product?.material?.split(' '),
        size: data?.product?.size?.includes('/') ? data?.product?.size?.split('/') : data?.product?.size?.split(' '),
        bending: data?.product?.bending?.includes('/') ? data?.product?.bending?.split('/') : data?.product?.bending?.split(' '),
        thickness: data?.product?.thickness?.includes('/') ? data?.product?.thickness?.split('/') : data?.product?.thickness?.split(' '),
        length: data?.product?.length?.includes('/') ? data?.product?.length?.split('/') : data?.product?.length?.split(' '),
        manufacturer: data?.product?.manufacturer,
        images: data?.product?.images,
        product_specifics:data?.product?.product_specifics,
    }
}

//запросы

export const getCatalog = createAsyncThunk(
    'home/getCatalog',
    async (props,{rejectedWithValue}) => {
        try {
            const {data} = await axiosInstance.get('v1/catalogs/')
            return data
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getCategory = createAsyncThunk(
    'home/getCategory',
    async (props, {rejectedWithValue}) => {
            try {
                const {data} = await axiosInstance.get(`v1/catalogs/${props}/`)
                return data
            }catch (error) {
                return rejectedWithValue(error?.detail)
            }
    }
)

export const getCategoryProducts = createAsyncThunk(
    'home/getCategoryProducts',
    async ({category, page = 1}, {rejectedWithValue}) => {
            try {
                const {data} = await axiosInstance.get(`v1/catalog/${category}/products/?page=${page}&page_size=16`)
                return data
            }catch (error) {
                return rejectedWithValue(error?.detail)
            }
    }
)

export const getSubCategory = createAsyncThunk(
    'home/getSubCategory',
    async (props, {rejectedWithValue}) => {
        try {
            const {data} = await axiosInstance.get(`/v1/categories/${props}/`)
            return data
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getSubCategoryProducts = createAsyncThunk(
    'home/getSubCategoryProducts',
    async ({subCategory, page = 1}, {rejectedWithValue}) => {
            try {
                const {data} = await axiosInstance.get(`v1/category/${subCategory}/products/?page=${page}`)
                return data
            }catch (error) {
                return rejectedWithValue(error?.detail)
            }
    }
)

export const getProducts = createAsyncThunk(
    'home/getProducts',
    async (props,{rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/subcategories/${props}/`)
            return data
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getProduct = createAsyncThunk(
    'home/getProduct',
    async (props, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/products/${props}/`);
            return data;
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getProductSpecifics = createAsyncThunk(
    'home/getProductSpecifics',
    async ({ id }, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/product/specifics/${id}/`);
            return _transformProduct(data);
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getProductByName = createAsyncThunk(
    'home/getProductByName',
    async (props, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/products?search=${props}`)
            return data.results;
        }catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getBanner = createAsyncThunk(
    'home/getBanner',
    async (props, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get('v1/banner/');
            return data;
        } catch (error) {
            return rejectedWithValue(error?.detail)
        }
    }
)

export const getBannerProducts = createAsyncThunk(
    'home/getBannerProducts',
    async (props, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get(`v1/banner/${props}/`);
            return data
        } catch (error) {
            return rejectedWithValue(error?.value)
        }
    }
)

export const getAddress = createAsyncThunk(
    'home/getAddress',
    async (props, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get('v1/branches/');
            return data;
        } catch (error) {
            return rejectedWithValue(error?.value)
        }
    }
)

export const getDiscountProducts = createAsyncThunk(
    'home/getDiscountProducts',
    async (_, {rejectedWithValue}) => {
        try {
            const { data } = await axiosInstance.get('v1/products/discount_products/');
            return data?.results;
        } catch (error) {
            return rejectedWithValue(error?.value)
        }
    }
)

export const getAllProducts = createAsyncThunk(
    'banner/getAllProducts',
    async (props, {rejectWithValue}) => {
        try {
            const { data } = await axiosInstance.get('v1/products/')
            return data 
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

//slice
const initialState = {
    catalog:'',
    slug:'',
    name:'',
    data: [],
    status:'',
    statusCategory:'',
    product: {},
    searchProduct: [],
    banners: [],
    address: [],
    discount_products: [],
    statusProducts:'',
    categoryProduct: [],
    product_status: '',
    specifics_status: '',
    specifics: []
}

const HomeSlice = createSlice({
    name:'home',
    initialState,
    reducers:{},
    extraReducers:{
        [getCategory.pending]: (state) => {
            state.statusCategory = 'pending'
        },
        [getCategory.fulfilled]: (state,actions) => {
            state.statusCategory = 'success';
            state.data = actions.payload
        },
        [getCategory.rejected]: (state,actions) => {
            state.statusCategory = 'rejected'
            state.data = actions.payload
        },
        // продукты
        [getCategoryProducts.pending]: (state) => {
            state.product_status = 'pending'
        },
        [getCategoryProducts.fulfilled]: (state,actions) => {
            state.product_status = 'success';
            state.categoryProduct = actions.payload
        },
        [getCategoryProducts.rejected]: (state,actions) => {
            state.product_status = 'rejected'
            state.categoryProduct = actions.payload
        },
        [getSubCategoryProducts.pending]: (state) => {
            state.product_status = 'pending'
        },
        [getSubCategoryProducts.fulfilled]: (state,actions) => {
            state.product_status = 'success';
            state.categoryProduct = actions.payload
        },
        [getSubCategoryProducts.rejected]: (state,actions) => {
            state.product_status = 'rejected'
            state.categoryProduct = actions.payload
        },
        //getSubCategoryProducts
        [getCatalog.pending]:(state) => {
            state.status = 'pending'
        },
        [getCatalog.fulfilled]:(state,actions) => {
            state.status = 'success'
            state.data = actions.payload
        },
        [getCatalog.rejected]:(state,actions) => {
            state.status = 'rejected'
            state.data = actions.payload
        },
        [getSubCategory.pending]:(state) => {
            state.status = 'pending'
        },
        [getSubCategory.fulfilled]:(state , actions) => {
            state.status = 'fulfilled'
            state.data = actions.payload
        },
        [getSubCategory.rejected]:(state,actions) => {
            state.status = 'rejected'
            state.data = actions.payload
        },
        //для нескольких продуктов
        [getProducts.pending]:(state) => {
            state.statusProducts = 'pending'
        },
        [getProducts.fulfilled]:(state,actions) => {
            state.statusProducts = 'fulfilled'
            state.data = actions.payload
        },
        [getProducts.rejected]:(state,actions) => {
            state.statusProducts = 'rejected'
            state.data = actions.payload
        },
        //для одного продукта
        [getProduct.pending]: (state) => {
            state.status = 'pending'
        },
        [getProduct.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.product = actions.payload;
        },
        [getProduct.rejected]: (state ,actions) => {
            state.status = 'rejected'
            state.product = actions.payload;
        },
        //Поиск
        [getProductByName.pending]: (state) => {
            state.status = 'pending';
        },
        [getProductByName.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.searchProduct = actions.payload;
        },
        [getProductByName.rejected]: (state, actions) => {
            state.status = 'rejected';
            state.searchProduct = actions.payload;
        },
        [getBanner.pending]: (state) => {
            state.status = 'pending';
        },
        [getBanner.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.banners = actions.payload;
        },
        [getBanner.rejected]: (state, actions) => {
            state.status = 'rejected';
            state.banners = actions.payload;
        },
        [getBannerProducts.pending]: (state) => {
            state.status = 'pending';
        },
        [getBannerProducts.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.data = actions.payload;
        },
        [getBannerProducts.rejected]: (state, actions) => {
            state.status = 'rejected';
            state.data = actions.payload;
        },
        [getAddress.pending]: (state) => {
            state.status = 'pending';
        },
        [getAddress.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.address = actions.payload
        },
        [getAddress.rejected]: (state, actions) => {
            state.status = 'rejected';
            state.address = actions.payload
        },
        [getDiscountProducts.pending]: (state) => {
            state.status = 'pending';
        },
        [getDiscountProducts.fulfilled]: (state, actions) => {
            state.status = 'fulfilled';
            state.discount_products = actions.payload
        },
        [getDiscountProducts.rejected]: (state, actions) => {
            state.status = 'rejected';
            // state.discount_products = actions.payload
        },
        [getAllProducts.pending]: (state) => {
            state.status = 'pending';
        },
        [getAllProducts.fulfilled]: (state, action) => {
            state.status = 'fulfilled';
            state.data = action.payload;
        },
        [getAllProducts.rejected]: (state, action) => {
            state.status = 'rejected';
            state.data = action.payload;
        },
        [getProductSpecifics.pending]: (state) => {
            state.specifics_status = 'pending';
        },
        [getProductSpecifics.fulfilled]: (state, action) => {
            state.specifics_status = 'fulfilled';
            state.specifics = action.payload;
        },
        [getProductSpecifics.rejected]: (state, action) => {
            state.specifics_status = 'rejected';
            state.specifics = action.payload;
        }
    }
})

export const HomeActions = HomeSlice.actions
export default HomeSlice