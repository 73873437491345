import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

// request 
export const getDeliveriesList = createAsyncThunk(
    "delivery/getDeliveriesList",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("v1/crm/delivery/payments/");
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const deleteDelivery = createAsyncThunk(
    "delivery/deleteDelivery",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/delivery/payments/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const editDelivery = createAsyncThunk(
    "delivery/editDelivery",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/delivery/payments/${props.id}/`, props.values);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const createDelivery = createAsyncThunk(
    "delivery/createDelivery",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(`v1/crm/delivery/payments/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// slice 
const DeliverySlice = createSlice({
    name: "delivery",
    initialState: {
        deliveries_list: [],
        deliveries_list_status: "",
    },
    reducers: {
    },
    extraReducers: {
        [getDeliveriesList.pending]: (state) => {
            state.deliveries_list_status = "pending";
        },
        [getDeliveriesList.fulfilled]: (state, actions) => {
            state.deliveries_list_status = "fulfilled";
            state.deliveries_list = actions.payload;
        },
        [getDeliveriesList.rejected]: (state) => {
            state.deliveries_list_status = "rejected";
        },
    },
});


export const {clearStatus} = DeliverySlice.actions
export default DeliverySlice