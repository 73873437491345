import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";

// request
export const getSpecificsList = createAsyncThunk(
    "specifics/getSpecificsList",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("v1/crm/product/specifics/");
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getSpecificVariants = createAsyncThunk(
    "specifics/getSpecificVariants",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/product/specifics/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// createspecifics
export const createSpecifics = createAsyncThunk(
    "specifics/createSpecifics",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post("v1/crm/product/specifics/", props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// get by id 
export const getSpecificsById = createAsyncThunk(
    "specifics/getSpecificsById",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`v1/crm/product/specifics/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }   
    }
)

// delete specific
export const deleteSpecifics = createAsyncThunk(
    "specifics/deleteSpecifics",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/product/specifics/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)


// edit specific
export const editSpecifics = createAsyncThunk(
    "specifics/editSpecifics",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/product/specifics/choices/update/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const editSpecificsTest = createAsyncThunk(
    "specifics/editSpecificsTest",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/product/specifics/${props?.id}/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// create choice
export const createSpecificsChoice = createAsyncThunk(
    "specifics/createSpecificsChoice",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(`v1/crm/product/specifics/${props?.product_specific_id}/`, props);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
// delete choice
export const deleteSpecificsChoice = createAsyncThunk(
    "specifics/deleteSpecificsChoice",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`v1/crm/product/specifics/choices/delete/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)


// slice 
const SpecificsSlice = createSlice({
    name: "specifics",
    initialState: {
        specificsList_status: "",
        specifics_list: [],

        create_status: "",
        
        specifics: {},
        
        create_choice_status: "",

        edit_status: "",
        edit_status_test:"",

        specifics_delete_status: "",
        choise_delete_status: "",
        edit_test_status:"",
    },
    reducers: {
        clearStatus: (state) => {
            state.specificsList_status = "";
            state.create_status = "";
            state.create_choice_status = "";
            state.edit_status = "";
            state.specifics_delete_status = "";
            state.choise_delete_status = "";
            state.edit_test_status = ""
        }
    },
    extraReducers: {
        [getSpecificsList.pending]: (state) => {
            state.specificsList_status = "pending";
        },
        [getSpecificsList.fulfilled]: (state, action) => {
            state.specificsList_status = "fulfilled";
            state.specifics_list = action.payload;
        },
        [getSpecificsList.rejected]: (state) => {
            state.specificsList_status = "rejected";
        },
        // -------------------------------------------------- //
        [createSpecifics.pending]: (state) => {
            state.create_status = "pending";
        },
        [createSpecifics.fulfilled]: (state, action) => {
            state.create_status = "fulfilled";
        },
        [createSpecifics.rejected]: (state) => {
            state.create_status = "rejected";
        },
        // -------------------------------------------------- //
        [getSpecificsById.pending]: (state) => {
            state.specificsList_status = "pending";
        },
        [getSpecificsById.fulfilled]: (state, action) => {
            state.specificsList_status = "fulfilled";
            state.specifics = action.payload;
        },
        [getSpecificsById.rejected]: (state) => {
            state.specificsList_status = "rejected";
        },
        // -------------------------------------------------- //
        [createSpecificsChoice.pending]: (state) => {
            state.create_choice_status = "pending";
        },
        [createSpecificsChoice.fulfilled]: (state, action) => {
            state.create_choice_status = "fulfilled";
        },
        [createSpecificsChoice.rejected]: (state) => {
            state.create_choice_status = "rejected";
        },  
        // -------------------------------------------------- //
        [editSpecifics.pending]: (state) => {
            state.edit_status = "pending";
        },
        [editSpecifics.fulfilled]: (state, action) => {
            state.edit_status = "fulfilled";
        },
        [editSpecifics.rejected]: (state) => {
            state.edit_status = "rejected";
        },
        // -------------------------------------------------- //
        [deleteSpecifics.pending]: (state) => {
            state.specifics_delete_status = "pending";
        },
        [deleteSpecifics.fulfilled]: (state, action) => {
            state.specifics_delete_status = "fulfilled";
        },
        [deleteSpecifics.rejected]: (state) => {
            state.specifics_delete_status = "rejected";
        },
        // -------------------------------------------------- //
        [deleteSpecificsChoice.pending]: (state) => {
            state.choise_delete_status = "pending";
        },
        [deleteSpecificsChoice.fulfilled]: (state, action) => {
            state.choise_delete_status = "fulfilled";
        },
        [deleteSpecificsChoice.rejected]: (state) => {
            state.choise_delete_status = "rejected";
        },
        // -------------------------------------------------- //
        [editSpecificsTest.pending]: (state) => {
            state.edit_test_status = "pending";
        },
        [editSpecificsTest.fulfilled]: (state, action) => {
            state.edit_test_status = "fulfilled";
        },
        [editSpecificsTest.rejected]: (state) => {
            state.edit_test_status = "rejected";
        },
    },
});

export const { clearStatus } = SpecificsSlice.actions
export default SpecificsSlice