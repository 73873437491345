import {createAsyncThunk, createSlice, isRejectedWithValue} from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstans";
import { ImageUploadingFetch } from "../../api/axiosInstans";


export const GetUser = createAsyncThunk(
    'getUser',
    async (props , {rejectWithValue}) => {
        try {
            const {data} = await axiosInstance.get('user-ru/profile/')
            return data[0]
        }catch (error) {
            return rejectWithValue(error?.default)
        }
    }
)

export const GetDeliveryInfo = createAsyncThunk(
    'GetDeliveryInfo',
    async (props , {rejectWithValue}) => {
        try {
            const {data} = await axiosInstance.get('v1/delivery/payments/list/')
            return data
        }catch (error) {
            return rejectWithValue(error?.default)
        }
    }
)

//редактироование пользователя
export const PatchUser = createAsyncThunk(
    'register/patchUser',
    async (user, {rejectWithValue}) => {
        try {
            const formData = new FormData();
            if(typeof user?.image !== 'string' && user.image[0]?.file !== undefined){
                formData.append("image", user.image[0].file)
            }
            for(let key in user){
                if(key !== 'image'){
                    formData.append(key, user[key])
                }
            }
            const {data} = await ImageUploadingFetch.patch(`user-ru/${user?.id}/` , formData)
            return data
        }catch (error) {
            return rejectWithValue(error?.detail)
        }
    }
)


//регистрация пользователя

export const Register = createAsyncThunk(
    'register/user',
    async (user , {rejectWithValue}) => {
        try {const {data} = await axiosInstance.post(`user-ru/`,user)
            return data
        }catch (error) {
            return rejectWithValue(error?.detail)
        }
    }
)



const initialState = {
    statusResult:'',
    statusRegister:'',
    statusSetting:'',
    delivery_info: [],
    user:{},
}

const RegisterUser = createSlice({
    name:'register',
    initialState,
    reducers:{},
    extraReducers:{
        [Register.pending]: (state ,actions) => {
            state.statusRegister = 'pending'
        },
        [Register.fulfilled]: (state , actions) => {
            state.statusRegister = 'fulfilled'
            state.user = actions.payload
        },
        [Register.rejected]: (state , actions) => {
            state.statusRegister = 'rejected'
            state.user = actions.payload
        },
        [GetUser.pending] : (state) => {
            state.statusResult = 'pending'
        },
        [GetUser.fulfilled] : (state, actions ) => {
            state.user = actions.payload
            state.statusResult = 'fulfilled'
        },
        [GetUser.rejected]:  (state , actions) => {
            state.statusResult = 'rejected'
            state.user = actions.payload
        },
        [PatchUser.pending]: (state) => {
            state.statusSetting = 'pending'
        },
        [PatchUser.fulfilled]: (state,actions ) => {
            state.statusSetting = 'fulfilled'
            state.user = actions.payload
        },
        [PatchUser.rejected]: (state , actions ) => {
            state.statusSetting = 'rejected'
            state.user = actions.payload
        },
        [GetDeliveryInfo.fulfilled]: (state,actions ) => {
            state.delivery_info = actions.payload || [];
            console.log(actions.payload)
        },
    }
})


export const RegisterActions = RegisterUser.actions
export default RegisterUser